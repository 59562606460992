import palette from '../palette';

export default {
  styleOverrides: {
    root: {
      fontSize: '16px',
      lineHeight: '29px',
      fontWeight: 500,
      letterSpacing: 0,
      color: palette.colors.error03,
      marginLeft: 0,
      marginRight: 0,
      marginTop: '6px',
    },
  },
};
