/* eslint-disable no-unused-vars */
import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

// * Hooks & Utils
import { ApolloError, useLazyQuery } from '@apollo/client';
import { isEmpty, isEqual } from 'lodash';

// * Queries
import { ME_QUERY } from '../../queries/auth/account';

// * Interfaces
import type { UserType } from '../../shared/types/user';

type UserContextType = {
  me: UserType;
  setMe: (user: UserType) => void;
  // @ts-ignore
  refetchMe: () => Promise<void>;
  error: ApolloError | undefined;
  loading: boolean;
  called: boolean;
};

export const UserContext = createContext<UserContextType>({
  me: {
    email: '',
    firstName: '',
    isResearchAnalyst: false,
    lastName: '',
    uuid: '',
    id: '',
    timezone: '',
    avatar: '',
    isAcaReviewer: false,
    allowSwitchRolesOnFrontend: false,
    notificationsFrequency: '',
    sendNewExpertCallTranscriptEmail: true,
    sendIfMeetingScheduledEmail: true,
    sendIfMeetingReadyForReviewEmail: true,
    accountFullLogo: '',
    accountAvatarLogo: '',
    account: {
      uuid: '',
      name: '',
      settings: {
        fullLogo: '',
        avatarLogo: '',
      },
    },
    accounts: [{
      uuid: '',
      name: '',
      settings: {
        fullLogo: '',
        avatarLogo: '',
      },
    }],
  },
  setMe: () => {},
  refetchMe: () => Promise.resolve(),
  error: undefined,
  loading: false,
  called: false,
});

type UserContextProviderProps = {
  children: ReactNode;
};

export const UserContextProvider: React.FC<UserContextProviderProps> = ({ children }) => {
  const [getMe, {
    data: { me: user = {} } = {},
    refetch: refetchMe,
    error,
    loading: meLoading,
    called: meCalled,
  }] = useLazyQuery(ME_QUERY);

  const [me, setMe] = useState<UserType>(user);
  const [loading, setLoading] = useState<boolean>(meLoading);
  const [called, setCalled] = useState<boolean>(meCalled);

  useEffect(() => {
    if (isEmpty(me)) {
      getMe();
    }
  // eslint-disable-next-line
  }, []);

  const handleRefetchMe = async () => {
    refetchMe().then(newData => {
      setMe(newData.data.me);
    });
  };

  useEffect(() => {
    if (!isEqual(me, user) && isEmpty(me)) {
      setMe(user);
    }
    if (meLoading !== loading) {
      setLoading(meLoading);
    }
    if (meCalled !== called) {
      setCalled(meCalled);
    }
    // eslint-disable-next-line
  }, [user, meLoading, called]);

  const value = useMemo(() => ({
    me,
    setMe,
    refetchMe: handleRefetchMe,
    error,
    loading,
    called,
    // eslint-disable-next-line
  }), [me, setMe, refetchMe, loading, error]); // Remove error from here

  return (
    <UserContext.Provider value={value}>
      {children}
    </UserContext.Provider>
  );
};

export const useMe = () => useContext(UserContext);
export type CallTabType = 'scheduled' | 'completed' | 'reviewed';

type AppContextType = {
  refetchCompletedCalls?: () => void;
  setRefetchCompletedCalls: (refetch: () => void) => void;
  refetchReviewedCalls?: () => void;
  setRefetchReviewedCalls: (refetch: () => void) => void;
  refetchScheduledCalls?: () => void;
  setRefetchScheduledCalls: (refetch: () => void) => void;
  refetchTranscript?: () => void;
  setRefetchTranscript: (refetch: () => void) => void;
  refetchMyTopics?: () => void;
  setRefetchMyTopics: (refetch: () => void) => void;
  refetchCurrentTopic?: () => void;
  setRefetchCurrentTopic: (refetch: () => void) => void;
  refetchAllComplianceReviewers?: () => void;
  setRefetchAllComplianceReviewers: (refetch: () => void) => void;
  callsSelectedTab: CallTabType;
  setCallsSelectedTabName: (tab: CallTabType) => void;
};

type AppContextProviderProps = {
  children: ReactNode;
};

export const AppContext = createContext<AppContextType>({
  refetchCompletedCalls: undefined,
  setRefetchCompletedCalls: () => {},
  refetchReviewedCalls: undefined,
  setRefetchReviewedCalls: () => {},
  refetchScheduledCalls: undefined,
  setRefetchScheduledCalls: () => {},
  refetchTranscript: undefined,
  setRefetchTranscript: () => {},
  refetchMyTopics: undefined,
  setRefetchMyTopics: () => {},
  refetchCurrentTopic: undefined,
  setRefetchCurrentTopic: () => {},
  callsSelectedTab: 'scheduled',
  setCallsSelectedTabName: () => {},
  refetchAllComplianceReviewers: undefined,
  setRefetchAllComplianceReviewers: () => {},
});

export const AppContextProvider: React.FC<AppContextProviderProps> = ({ children }) => {
  const [refetchCompletedCalls, setRefetchCompletedCalls] = useState<() => void>();
  const [refetchReviewedCalls, setRefetchReviewedCalls] = useState<() => void>();
  const [refetchScheduledCalls, setRefetchScheduledCalls] = useState<() => void>();
  const [refetchTranscript, setRefetchTranscript] = useState<() => void>();
  const [refetchMyTopics, setRefetchMyTopics] = useState<() => void>();
  const [refetchCurrentTopic, setRefetchCurrentTopic] = useState<() => void>();
  const [refetchAllComplianceReviewers, setRefetchAllComplianceReviewers] = useState<() => void>();
  const [callsSelectedTab, setCallsSelectedTab] = useState<CallTabType>('scheduled');

  const providerSetRefetchCompletedCalls = (newRefetch: () => void) => {
    setRefetchCompletedCalls(() => newRefetch);
  };

  const providerSetRefetchReviewedCalls = (newRefetch: () => void) => {
    setRefetchReviewedCalls(() => newRefetch);
  };

  const providerSetRefetchScheduledCalls = (newRefetch: () => void) => {
    setRefetchScheduledCalls(() => newRefetch);
  };

  const providerSetRefetchTranscript = (newRefetch: () => void) => {
    setRefetchTranscript(() => newRefetch);
  };

  const providerSetRefetchMyTopics = (newRefetch: () => void) => {
    setRefetchMyTopics(() => newRefetch);
  };

  const providerSetRefetchCurrentTopic = (newRefetch: () => void) => {
    setRefetchCurrentTopic(() => newRefetch);
  };

  const providerSetRefetchAllComplianceReviewers = (newRefetch: () => void) => {
    setRefetchAllComplianceReviewers(() => newRefetch);
  };

  const providerSetSelectedTabName = (tabName: CallTabType) => {
    setCallsSelectedTab(tabName);
  };

  const value = useMemo(() => ({
    refetchCompletedCalls,
    setRefetchCompletedCalls: providerSetRefetchCompletedCalls,
    refetchReviewedCalls,
    setRefetchReviewedCalls: providerSetRefetchReviewedCalls,
    refetchScheduledCalls,
    setRefetchScheduledCalls: providerSetRefetchScheduledCalls,
    refetchTranscript,
    setRefetchTranscript: providerSetRefetchTranscript,
    setRefetchMyTopics: providerSetRefetchMyTopics,
    refetchMyTopics,
    refetchCurrentTopic,
    setRefetchCurrentTopic: providerSetRefetchCurrentTopic,
    callsSelectedTab,
    setCallsSelectedTabName: providerSetSelectedTabName,
    refetchAllComplianceReviewers,
    setRefetchAllComplianceReviewers: providerSetRefetchAllComplianceReviewers,
  }), [
    refetchCompletedCalls,
    refetchReviewedCalls,
    refetchScheduledCalls,
    refetchTranscript,
    refetchMyTopics,
    refetchCurrentTopic,
    callsSelectedTab,
    refetchAllComplianceReviewers,
  ]);

  return (
    <AppContext.Provider value={value}>
      {children}
    </AppContext.Provider>
  );
};
