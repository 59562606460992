import MuiButton from './MuiButton';
import MuiButtonBase from './MuiButtonBase';
import MuiDialog from './MuiDialog';
import MuiInputAdornment from './MuiInputAdornment';
import MuiInputBase from './MuiInputBase';
import MuiFormHelperText from './MuiFormHelperText';
import MuiOutlinedInput from './MuiOutlinedInput';

const overrides = {
  MuiButton,
  MuiButtonBase,
  MuiDialog,
  MuiInputAdornment,
  MuiInputBase,
  MuiFormHelperText,
  MuiOutlinedInput,
};

export default overrides;
