import {
  ApolloClient,
  InMemoryCache,
  from,
  // createHttpLink,
} from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from '@apollo/link-context';

// const httpLink = createHttpLink({
//   uri: process.env.REACT_APP_APOLLO_CLIENT_URI,
// });

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token') || '';

  return {
    headers: {
      ...headers,
      authorization: token ? `JWT ${token}` : '',
    },
  };
});

const uploadLink = createUploadLink({ uri: process.env.REACT_APP_APOLLO_CLIENT_URI });

const client = new ApolloClient({
  // link: authLink.concat(httpLink),
  link: from([authLink, uploadLink]),
  cache: new InMemoryCache(),
});

export default client;
