import palette from '../palette';

export default {
  styleOverrides: {
    contained: {
      background: palette.primary.main,
      color: palette.white,
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 400,
      letterSpacing: 0,
      borderRadius: '12px',
      boxShadow: 'none',
      padding: '10px 12px',
      '& .MuiButton-endIcon': {
        transition: '250ms',
        color: palette.white,
      },
      '&:hover': {
        background: palette.primary.dark,
        boxShadow: 'none',
      },
      '&:active': {
        background: palette.primary.main,
        boxShadow: 'none',
      },
      '&:disabled': {
        background: palette.primary.light,
        color: palette.white,
        boxShadow: 'none',
        filter: 'none',
      },
    },
    containedSizeLarge: {
      fontSize: '16px',
      lineHeight: '29px',
      fontWeight: 600,
      padding: '14px 25px 15px',
      borderRadius: '15px',
    },
    text: {
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 500,
      letterSpacing: 0,
      color: palette.colors.gray04,
      padding: 0,
      '&:hover': {
        color: palette.primary.main,
        boxShadow: 'none',
        backgroundColor: 'transparent',
        textDecoration: 'underline',
      },
      '&:active': {
        color: palette.primary.main,
        boxShadow: 'none',
        backgroundColor: 'transparent',
        textDecoration: 'underline',
      },
      '&:disabled': {
        color: palette.colors.gray03,
        boxShadow: 'none',
        backgroundColor: 'transparent',
      },
    },
  },
};

