import React, { Suspense, lazy } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { ApolloProvider } from '@apollo/client';
import LogRocket from 'logrocket';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { LicenseInfo } from '@mui/x-license';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import client from './client';
import paths from './paths/paths';
import theme from './theme';
import './assets/scss/index.scss';
import ProtectedRoute from './shared/utils/protectedRoute';
import ValidateResetPasswordLinkGuard from './components/ResetPasswordLinkValidator';
import { AppContextProvider, useMe, UserContextProvider } from './components/AppContextProvider';

dayjs.extend(utc);
dayjs.extend(timezone);

// @ts-ignore
LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_X_PRO_LICENSE_KEY);

LogRocket.init('k4v8ph/encore-compliance');

// Lazy-loaded components
const MainApp = lazy(() => import('./layouts/MainApp'));
const ResearchAppCalls = lazy(() => import('./pages/ResearchAppCalls'));
const VerifyToken = lazy(() => import('./pages/VerifyToken'));
const SignInEmail = lazy(() => import('./pages/SignInEmail'));
const SignInPassword = lazy(() => import('./pages/SignInPassword'));
const Page404 = lazy(() => import('./pages/404'));
const UserSettings = lazy(() => import('./pages/UserSettings'));
const OrganizationSettings = lazy(() => import('./pages/OrganizationSettings'));
const Reports = lazy(() => import('./pages/Reports'));
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'));
const ResetPassword = lazy(() => import('./pages/ResetPassword'));
const Topics = lazy(() => import('./pages/Topics'));

const Integrations = () => {
  const { me } = useMe();

  if (process.env.REACT_APP_TARGET === 'prod') {
    LogRocket.identify(me ? me.id : 'None', {
      name: `${me.firstName} ${me.lastName}` || '',
      email: me.email || '',
      id: me.id || '',
    });
  }

  return null;
};

const MainLayoutSwitch = () => {
  const { me } = useMe();
  const { isResearchAnalyst } = me;

  return isResearchAnalyst ? <ResearchAppCalls /> : <MainApp />;
};

const AppRoutes = () => (
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <UserContextProvider>
          <AppContextProvider>
            <DndProvider backend={HTML5Backend}>
              <Suspense fallback={<div>Loading...</div>}>
                <Routes>
                  <Route element={<ProtectedRoute />}>
                    <Route path="/" element={<Navigate to={paths.home.reverse()} />} />
                    <Route path={paths.home.pattern} element={<MainLayoutSwitch />} />
                    <Route path={paths.topics.pattern} element={<Topics />} />
                    <Route path={paths.userSettings.pattern} element={<UserSettings />} />
                    <Route path={paths.organizationSettings.pattern} element={<OrganizationSettings />} />
                    <Route path={paths.reports.pattern} element={<Reports />} />
                  </Route>
                  <Route path={paths.forgotPassword.pattern} element={<ForgotPassword />} />
                  <Route path={paths.signInEmail.pattern} element={<SignInEmail />} />
                  <Route path={paths.signInPassword.pattern} element={<SignInPassword />} />
                  <Route path={paths.verifyToken.pattern} element={<VerifyToken />} />
                  <Route
                    path={paths.firstLoginPassword.pattern}
                    element={(
                      <ValidateResetPasswordLinkGuard>
                        <ResetPassword
                          title="Welcome to Encore"
                          subtitle="Please set up your password"
                          successMessage="Password set successfully"
                          buttonText="Set password"
                        />
                      </ValidateResetPasswordLinkGuard>
                  )}
                  />
                  <Route
                    path={paths.resetPassword.pattern}
                    element={(
                      <ValidateResetPasswordLinkGuard>
                        <ResetPassword
                          title="Reset your password"
                          subtitle="Enter your password below"
                          successMessage="Password reset successfully"
                          buttonText="Reset password"
                        />
                      </ValidateResetPasswordLinkGuard>
                  )}
                  />
                  <Route path="*" element={<Page404 />} />
                </Routes>
              </Suspense>
              <Integrations />
            </DndProvider>
          </AppContextProvider>
        </UserContextProvider>
      </ThemeProvider>
    </ApolloProvider>
  </LocalizationProvider>
);

const App = () => (
  <Router>
    <AppRoutes />
  </Router>
);

export default App;
