import palette from '../palette';

export default {
  styleOverrides: {
    positionStart: {
      color: palette.icon,
      marginRight: '10px',
    },
    positionEnd: {
      color: palette.icon,
      marginRight: '10px',
      '& .MuiIconButton-root': {
        padding: 0,
        color: palette.icon,
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
    },
  },
};
