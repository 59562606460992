import palette from '../palette';

export default {
  styleOverrides: {
    root: {
      borderTopLeftRadius: '15px',
      borderTopRightRadius: '15px',
      borderRadius: '15px',
      backgroundColor: palette.background.paper,
      padding: '0 15px',
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: palette.divider,
        transition: 'all .25s',
        '& legend': {
          maxWidth: '0.01px',
        },
      },
      '&:hover:not(.Mui-error):not(.Mui-disabled):not(.Mui-focused)': {
        backgroundColor: palette.background.paper,
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: palette.primary.main,
        },
      },
      '&.Mui-error': {
        backgroundColor: palette.background.paper,
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: palette.colors.error03,
          borderWidth: 1,
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderWidth: 1,
        },
      },
      '&.Mui-focused:not(.Mui-error)': {
        backgroundColor: palette.background.paper,
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: palette.primary.main,
          borderWidth: 1,
          boxShadow: `0px 12px 23px ${palette.colors.focusedInputShadow}`,
        },
      },
      '&.Mui-disabled': {
        border: 'none',
        backgroundColor: palette.background.default,
        color: palette.divider,
      },
    },
    notchedOutline: {
      borderWidth: 1,
    },
  },
};

