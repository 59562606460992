import { useQuery } from '@apollo/client';
import { useParams, useNavigate } from 'react-router';
import paths from '../../paths/paths';
import { VALIDATE_RESET_PASSWORD_LINK_QUERY } from '../../queries/auth/account';
import LoadingScreen from '../LoadingScreen';

type ValidateResetPasswordLinkType = {
  children: JSX.Element,
}

const ValidateResetPasswordLinkGuard = ({ children }: ValidateResetPasswordLinkType): JSX.Element => {
  const { hashid } = useParams<{ hashid: string }>();
  const navigate = useNavigate();

  const { data = {}, error } = useQuery(VALIDATE_RESET_PASSWORD_LINK_QUERY, {
    variables: { linkHash: hashid },
  });

  if (data.passwordResetHash?.uuid === hashid) {
    return children;
  }

  if (error) {
    navigate(paths.signInEmail.pattern);
  }
  return <LoadingScreen />;
};

export default ValidateResetPasswordLinkGuard;
