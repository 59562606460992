// @ts-ignore
// eslint-disable-next-line
import RoutePath from '../shared/utils/namedUrls';

export default {
  signIn: new RoutePath('/account/sign-in'),
  signInEmail: new RoutePath('/account/sign-in/email'),
  signInPassword: new RoutePath('/account/sign-in/password/:email'),
  verifyToken: new RoutePath('/account/token-verify/:token'),
  forgotPassword: new RoutePath('/account/forgot-password'),
  resetPassword: new RoutePath('/account/reset-password/:hashid'),
  firstLoginPassword: new RoutePath('/account/set-new-password/:hashid'),
  userSettings: new RoutePath('/account/user-settings'),
  organizationSettings: new RoutePath('/account/organization-settings'),
  reports: new RoutePath('/account/reports'),
  home: new RoutePath('/calls/:transcriptId?'),
  companies: new RoutePath('/companies/:companyId?'),
  topics: new RoutePath('/topics/:topicId?'),
};
