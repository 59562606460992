const white = '#FFFFFF';
const black = '#000000';

export default {
  black,
  white,
  colors: {
    anotherGray: '#8A94A6',
    gray01: '#F1F4F6',
    gray02: '#E4E8EE',
    gray03: '#D6DDE5',
    gray04: '#9DB4CB',
    gray05: '#768DA9',
    gray06: '#566E91',
    gray07: '#3B5179',
    gray08: '#253862',
    gray09: '#162651',
    gray10: '#05133A',
    error01: '#FFE9E9',
    error02: '#FEBEBE',
    error03: '#FC5A5A',
    error04: '#FA2020',
    placeholder: '#B0B7C3',
    bgColor: '#F2F2F2',
    focusedInputShadow: 'rgba(55, 125, 255, 0.06)',
    primary01: '#EBF7FF',
    primary02: '#C7CEFF',
    primary05: '#509CF1',
    success01: '#DEF8EB',
    success02: '#9BEAC3',
    success03: '#23A566',
    success04: '#1D8854',
    warning01: '#FFF2E3',
    warning02: '#FFD9AB',
    warning04: '#F08B0F',
    info05: '#2094FF',
    secondaryHover: '#1A3E50',
  },
  primary: {
    contrastText: white,
    light: '#A2D5FC',
    main: '#1A73E8',
    dark: '#1359C7',
  },
  secondary: {
    contrastText: white,
    main: '#00283D',
  },
  success: {
    contrastText: white,
    main: '#179F5E',
  },
  info: {
    contrastText: black,
    main: '#1091FF',
  },
  warning: {
    contrastText: white,
    main: '#F99316',
  },
  error: {
    contrastText: white,
    main: '#FF4242',
  },
  text: {
    primary: '#162651',
    secondary: '#8293A6',
    link: '#1168A7',
  },
  background: {
    default: '#F4F6FA',
    paper: white,
  },
  icon: '#C1C7D0',
  divider: '#F1F4F6',
};
