// import palette from '../palette';

export default {
  styleOverrides: {
    root: {
      '& .MuiBackdrop-root': {
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
      },
    },
    paper: {
      maxWidth: '520px',
      boxShadow: '0px 44px 65px rgba(176, 183, 195, 0.19)',
      borderRadius: '15px',
    },
  },
};
