import palette from '../palette';

export default {
  styleOverrides: {
    root: {
      fontSize: '16px',
      lineHeight: '29px',
      fontWeight: 500,
      letterSpacing: 0,
      '& .MuiOutlinedInput-input': {
        height: '29px',
        padding: '14px 0 15px',
        borderRadius: 0,
        '&:-webkit-autofill': {
          WebkitBoxShadow: '0 0 0 1000px #FFFFFF inset',
        },
      },
      '&::placeholder': {
        opacity: 1,
        color: palette.colors.placeholder,
      },
      '::-ms-input-placeholder': {
        opacity: 1,
        color: palette.colors.placeholder,
      },
      ':-ms-input-placeholder': {
        opacity: 1,
        color: palette.colors.placeholder,
      },
      '&:disabled': {
        pointerEvents: 'none',
        background: palette.background.default,
        color: palette.colors.placeholder,
      },
    },
  },
};
