import React from 'react';
import { isEmpty } from 'lodash';
import { useNavigate, Outlet } from 'react-router-dom';
import { useMe, AppContextProvider } from '../../components/AppContextProvider';
import LoadingScreen from '../../components/LoadingScreen';
import paths from '../../paths/paths';

const ProtectedRoute = () => {
  const navigate = useNavigate();
  const { me, loading, called } = useMe();

  if (loading) {
    return <LoadingScreen />;
  }
  if (called && isEmpty(me)) {
    navigate(paths.signInEmail.pattern);
  }
  return (
    <AppContextProvider>
      <Outlet />
    </AppContextProvider>
  );
};

export default ProtectedRoute;
