import palette from './palette';

const typography = {
  fontFamily: [
    'Inter',
    'Open Sans',
    'Arial',
  ].join(','),
  h1: {
    color: palette.text.primary,
    fontWeight: 700,
    fontSize: '2rem',
    letterSpacing: '-0.01562em',
    lineHeight: 1.31,
  },
  h2: {
    color: palette.text.primary,
    fontWeight: 700,
    fontSize: '1.625rem',
    letterSpacing: '-0.00833em',
    lineHeight: 1.23,
  },
  h3: {
    color: palette.text.primary,
    fontWeight: 700,
    fontSize: '32px',
    letterSpacing: 0,
    lineHeight: '40px',
  },
  h4: {
    color: palette.text.primary,
    fontWeight: 700,
    fontSize: '26px',
    letterSpacing: 0,
    lineHeight: '29px',
  },
  h5: {
    color: palette.text.primary,
    fontWeight: 700,
    fontSize: '20px',
    letterSpacing: 0,
    lineHeight: '24px',
  },
  h6: {
    color: palette.text.primary,
    fontWeight: 700,
    fontSize: '18px',
    letterSpacing: 0,
    lineHeight: '22px',
  },
  subtitle1: {
    color: palette.text.primary,
    fontWeight: 600,
    fontSize: '16px',
    letterSpacing: 0,
    lineHeight: '24px',
  },
  subtitle2: {
    color: palette.text.primary,
    fontWeight: 600,
    fontSize: '14px',
    letterSpacing: 0,
    lineHeight: '22px',
  },
  body1: {
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: '16px',
    letterSpacing: 0,
    lineHeight: '24px',
  },
  body2: {
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: '18px',
    letterSpacing: 0,
    lineHeight: '29px',
  },
  button: {
    fontSize: '0.875rem',
    lineHeight: 1.14,
    fontWeight: 400,
    letterSpacing: '0.028em',
  },
  caption: {
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: '12px',
    letterSpacing: 0,
    lineHeight: '18px',
  },
  overline: {
    color: palette.text.primary,
    fontWeight: 600,
    fontSize: '16px',
    letterSpacing: 0,
    lineHeight: '36px',
  },
};

export default typography;
